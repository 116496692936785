import { createApiRef } from '@backstage/core-plugin-api';
import {
  CreateHiddenItemRequest,
  CveProductRequest,
  CveRequest,
  CveType,
  DashboardResponse,
  ExposedSecretsResponse,
  HiddenItem,
  HiddenItemIdentifier,
  HiddenItemsResponse,
  HiddenItemsTableRequest,
  MisconfigurationsResponse,
  VulnerabilitiesResponse
} from '../types';
import { BaseProductMetaApiClient } from './baseApi';

export interface CveApi {
  getCveDashboard: (request: CveProductRequest) => Promise<DashboardResponse>;
  getCveVulnerabilities: (request: CveRequest) => Promise<VulnerabilitiesResponse>;
  getCveMisconfigurations: (request: CveRequest) => Promise<MisconfigurationsResponse>;
  getCveExposedSecrets: (request: CveRequest) => Promise<ExposedSecretsResponse>;
  getHiddenItems: (request: HiddenItemsTableRequest) => Promise<HiddenItemsResponse>;
  postHiddenItem(
    cveType: CveType,
    namespace: string,
    selectedIdentifiers: HiddenItemIdentifier[],
    comment?: string
  ): Promise<void>;
  deleteHiddenItem(id: string): Promise<void>;
}

export const productMetaCveApiRef = createApiRef<CveApi>({
  id: 'plugin.product-meta.cve-service'
});

export class ProductMetaCveApiClient extends BaseProductMetaApiClient implements CveApi {
  async getCveDashboard(request: CveProductRequest) {
    const queryParams = this.cleanQueryParams(request);
    const response = await this.fetchWithAuth(`/v1/cve/dashboard?${queryParams}`);
    return response.json();
  }

  async getCveVulnerabilities(request: CveRequest) {
    const queryParams = this.cleanQueryParams(request);
    const response = await this.fetchWithAuth(`/v1/cve/vulnerabilities?${queryParams}`);
    return response.json();
  }

  async getCveMisconfigurations(request: CveRequest) {
    const queryParams = this.cleanQueryParams(request);
    const response = await this.fetchWithAuth(`/v1/cve/misconfigurations?${queryParams}`);
    return response.json();
  }

  async getCveExposedSecrets(request: CveRequest) {
    const queryParams = this.cleanQueryParams(request);
    const response = await this.fetchWithAuth(`/v1/cve/exposed-secrets?${queryParams}`);
    return response.json();
  }

  async getHiddenItems(request: HiddenItemsTableRequest) {
    const queryParams = this.cleanQueryParams(request);
    const response = await this.fetchWithAuth(`/v1/cve/hidden-items?${queryParams}`);
    const result = await response.json();
    result.items.forEach((item: HiddenItem) => {
      item.createdAt = new Date(item.createdAt);
      item.id = (item as any)._id;
    });
    return result;
  }

  async postHiddenItem(
    cveType: CveType,
    namespace: string,
    selectedIdentifiers: HiddenItemIdentifier[],
    comment?: string
  ) {
    const request: CreateHiddenItemRequest = {
      type: cveType,
      namespace: namespace,
      identifiers: selectedIdentifiers,
      comment: comment
    };
    await this.fetchWithAuth('/v1/cve/hidden-items', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    });
  }

  async deleteHiddenItem(id: string) {
    await this.fetchWithAuth(`/v1/cve/hidden-items/${id}`, {
      method: 'DELETE'
    });
  }

  /**
   * Removes all undefined, null or empty values from query string.
   */
  private cleanQueryParams = (request: Record<string, any>) => {
    Object.keys(request).forEach((key) => {
      const isEmpty = request[key] === undefined || request[key] === null || request[key] === '';
      if (isEmpty) {
        delete request[key];
      }
    });

    const queryParams = new URLSearchParams(request);
    return queryParams.toString();
  };
}
