import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { ProductMetaApiClient, productMetaApiRef } from './apis/product.api';
import { ProductMetaMsalClient, productMetaMsalRef } from './apis/msal.api';
import { ProductMetaCveApiClient, productMetaCveApiRef } from './apis/cve.api';

export const productMetaPlugin = createPlugin({
  id: 'product-meta',
  routes: {
    root: rootRouteRef
  },
  apis: [
    // Add MsalApi
    createApiFactory({
      api: productMetaMsalRef,
      deps: { configApi: configApiRef, discoveryApi: discoveryApiRef },
      factory: ({ configApi, discoveryApi }) => new ProductMetaMsalClient({ configApi, discoveryApi })
    }),
    // Add ProductMetaApi
    createApiFactory({
      api: productMetaApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        productMetaMsal: productMetaMsalRef
      },
      factory: ({ discoveryApi, productMetaMsal }) => new ProductMetaApiClient({ discoveryApi, productMetaMsal })
    }),
    // Add CveApi
    createApiFactory({
      api: productMetaCveApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        productMetaMsal: productMetaMsalRef
      },
      factory: ({ discoveryApi, productMetaMsal }) => new ProductMetaCveApiClient({ discoveryApi, productMetaMsal })
    })
  ]
});

export const ProductMetaPage = productMetaPlugin.provide(
  createRoutableExtension({
    name: 'ProductMetaRoot',
    component: async () => (await import('./components/ProviderComponent')).ProviderComponent,
    mountPoint: rootRouteRef
  })
);
