import {
  AuthenticationResult,
  Configuration,
  LogLevel,
  PopupRequest,
  PublicClientApplication
} from '@azure/msal-browser';
import { ConfigApi, createApiRef, DiscoveryApi } from '@backstage/core-plugin-api';
import { Config } from '../../config';
import { JumphostProvisionData } from '../types';

export interface ProductMetaMsalApi {
  pca: PublicClientApplication;
  tokenRequestConfig: PopupRequest;
  onboardingDocumentationUrl: string;
  onboardingProcessUrl: string;
  getAccessToken: () => Promise<string>;
  loginPopup: () => Promise<AuthenticationResult | undefined>;
  logout: () => Promise<void>;
  getAccessTokenForGraph: () => Promise<string>;
  userIsAdmin: () => Promise<boolean>;
  provisionJumpHost: (data: JumphostProvisionData) => Promise<Response>;
}

export const productMetaMsalRef = createApiRef<ProductMetaMsalApi>({
  id: 'plugin.product-meta.msal'
});

const msalLoggerCallback = (_: any, message: string, containsPii: boolean) => {
  // Do not log personal information
  if (containsPii) {
    return;
  }

  // eslint-disable-next-line no-console
  console.log(message);
};

export class ProductMetaMsalClient implements ProductMetaMsalApi {
  private readonly _pca: PublicClientApplication;
  private readonly _tokenRequestConfig: PopupRequest;
  private readonly _onboardingDocumentationUrl: string;
  private readonly _onboardingProcessUrl: string;
  private readonly _discoveryApi: DiscoveryApi;

  get pca(): PublicClientApplication {
    return this._pca;
  }

  get tokenRequestConfig(): PopupRequest {
    return this._tokenRequestConfig;
  }

  get onboardingDocumentationUrl(): string {
    return this._onboardingDocumentationUrl;
  }

  get onboardingProcessUrl(): string {
    return this._onboardingProcessUrl;
  }

  constructor({ configApi, discoveryApi }: { configApi: ConfigApi; discoveryApi: DiscoveryApi }) {
    const { clientId, authority, scopes, msalLogging, onboardingDocumentationUrl, onboardingProcessUrl } =
      configApi.get<Config['mxp']>('mxp');

    // whatever URL the backstage is running, the plugin is located at /product-meta
    // without specifying a redirect URL, MSAL would use the current location
    // which will fail when directly routing to a more deeper URL like /product-meta/product/abc
    // e.g. for a URL https://foo.bar:1234/x/y/z the location.origin returns https://foo.bar:1234
    const redirectUri = `${location.origin}/product-meta`;

    const configuration: Configuration = {
      auth: {
        clientId,
        authority,
        redirectUri
      },
      system: {
        loggerOptions:
          msalLogging.toString() === 'true'
            ? {
                logLevel: LogLevel.Verbose,
                loggerCallback: msalLoggerCallback
              }
            : undefined
      }
    };
    this._pca = new PublicClientApplication(configuration);
    this._tokenRequestConfig = { scopes };
    this._onboardingDocumentationUrl = onboardingDocumentationUrl;
    this._onboardingProcessUrl = onboardingProcessUrl;
    this._discoveryApi = discoveryApi;
  }

  async loginPopup(): Promise<AuthenticationResult | undefined> {
    // if we have an active account, a silent login can be done
    const account = this._pca.getActiveAccount() || undefined;

    if (account) {
      try {
        return await this._pca.acquireTokenSilent({
          account,
          ...this._tokenRequestConfig
        });
      } catch {
        // If the refreshtoken is expired, interactive login is required
        return await this._pca.acquireTokenPopup({
          ...this._tokenRequestConfig,
          loginHint: account.username
        });
      }
    }

    try {
      const response = await this._pca.acquireTokenPopup(this._tokenRequestConfig);
      this._pca.setActiveAccount(response.account);
      return response;
    } catch {
      return undefined;
    }
  }

  async getAccessToken() {
    const _msalLogin = await this.loginPopup();

    if (!_msalLogin) {
      throw new Error('Could not get access token');
    }

    return _msalLogin.accessToken;
  }

  async userIsAdmin() {
    const _msalLogin = await this.loginPopup();
    if (!_msalLogin) {
      throw new Error('Could not get access token');
    }

    const claims = _msalLogin?.idTokenClaims as any;
    if (!claims || !Array.isArray(claims.roles)) {
      return false;
    }

    return claims.roles.includes('Mxp.Admin');
  }

  async getAccessTokenForGraph() {
    const token = await this.pca.acquireTokenSilent({
      scopes: ['openid', 'email', 'user.readbasic.all'],
      resourceRequestUri: 'https://graph.microsoft.com/',
      account: this.pca.getActiveAccount()!
    });

    if (!token) {
      throw new Error('Could not get Bearer Token');
    }

    return token.accessToken;
  }

  async logout() {
    await this._pca.logoutRedirect({
      onRedirectNavigate: (_) => {
        // only perform a local logout (AB#725367)
        // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md#skipping-the-server-sign-out
        return false;
      }
    });
  }

  async provisionJumpHost(data: JumphostProvisionData) {
    const headers = new Headers();
    const token = await this.getAccessToken();
    const jumphostUrl = `${await this._discoveryApi.getBaseUrl('proxy')}/jumphost-function}`;
    const bearer = `Bearer ${token}`;
    headers.append('Authorization', bearer);
    headers.append('Content-Type', 'application/json');

    return fetch(jumphostUrl, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    });
  }
}
