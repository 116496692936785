import React, { PropsWithChildren, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import LogoFull from './LogoFull';
import {
  DashboardIcon,
  DocsIcon,
  Link,
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  SidebarSubmenuItem,
} from '@backstage/core-components';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useSidebarStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
  inlineSubmenu: {
    width: '100%',
    borderLeft: 'solid 3px #9BF0E1',
    backgroundColor: 'rgb(0 0 0 / 10%)',
    '&  > div > a': {
      paddingLeft: '40px',
    },
  },
  inlineSubmenuDivider: {
    background: '#4b4b4b',
  },
});

const SidebarLogo = () => {
  const classes = useSidebarStyles();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        <LogoFull />
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const [submenuOpen, setSubmenuOpen] = useState<boolean>(false);
  const classes = useSidebarStyles();

  const configApi = useApi(configApiRef);
  const mxpConfig = configApi.getConfig('mxp');
  const documentationUrl = mxpConfig.getString('documentationUrl');
  const costDashboardUrl = mxpConfig.getString('costDashboardUrl');
  const enabledFeaturesConfig = mxpConfig.getOptionalConfig('features');

  const expandSubmenu = () => {
    setSubmenuOpen(true);
  };

  const closeLeave = () => {
    setSubmenuOpen(false);
  };

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />

        <SidebarDivider />
        <div onMouseEnter={expandSubmenu} onMouseLeave={closeLeave}>
          <SidebarItem icon={HomeIcon} to="product-meta" text="Product Meta">
            {submenuOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </SidebarItem>
          <div
            className={classes.inlineSubmenu}
            style={{ display: submenuOpen ? 'block' : 'none' }}
          >
            <SidebarSubmenuItem
              title="Product Configuration"
              to="product-meta/product-configuration"
              icon={BuildRoundedIcon}
            />
            {enabledFeaturesConfig?.getOptionalBoolean('cve') && (
              <SidebarSubmenuItem
                title="CVE"
                to="product-meta/cve"
                icon={CloudQueueIcon}
              />
            )}
            <SidebarDivider className={classes.inlineSubmenuDivider} />
            <SidebarSubmenuItem
              title="Documentation"
              to={documentationUrl}
              icon={DocsIcon}
            />
            <SidebarSubmenuItem
              title="Cost Dashboard"
              to={costDashboardUrl}
              icon={DashboardIcon}
            />
          </div>
        </div>

        <SidebarSpace />
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
