import { DiscoveryApi } from "@backstage/core-plugin-api";
import { ApiError } from "./apiError";
import { ProductMetaMsalApi } from "./msal.api";

export class BaseProductMetaApiClient {
  readonly discoveryApi: DiscoveryApi;
  readonly productMetaMsal: ProductMetaMsalApi;

  constructor({ discoveryApi, productMetaMsal }: { discoveryApi: DiscoveryApi; productMetaMsal: ProductMetaMsalApi }) {
    this.discoveryApi = discoveryApi;
    this.productMetaMsal = productMetaMsal;
  }

  async fetch(input: string, init?: RequestInit): Promise<Response> {
    // As configured previously for the backend proxy
    const proxyUri = `${await this.discoveryApi.getBaseUrl('proxy')}/product-meta`;

    const resp = await fetch(`${proxyUri}${input}`, init);
    if (!resp.ok) {
      const body = await resp.json();
      throw new ApiError(body);
    }

    return resp;
  }

  async fetchWithAuth(input: string, init?: RequestInit): Promise<Response> {
    const token = await this.productMetaMsal.getAccessToken();
    if (!token) {
      throw new Error('Could not get Bearer Token');
    }

    return await this.fetch(input, {
      ...init,
      headers: {
        ...init?.headers,
        Authorization: `Bearer ${token}`
      }
    });
  }
}