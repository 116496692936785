import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apis } from './apis';
import { Root } from './components/Root';
import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';

import { ProductMetaPage } from '@internal/plugin-product-meta';

const app = createApp({
	apis
});

const routes = (
	<FlatRoutes>
		<Route path="/"
		       element={<Navigate to="product-meta"/>}/>
		<Route path="/product-meta"
		       element={<ProductMetaPage/>}/>
	</FlatRoutes>
);

export default app.createRoot(
	<>
		<AlertDisplay/>
		<OAuthRequestDialog/>
		<AppRouter>
			<Root>{routes}</Root>
		</AppRouter>
	</>,
);
